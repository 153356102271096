import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ExternaldataService {
  headers:HttpHeaders;
  cors = 'https://corsproxyserve.herokuapp.com/';

  constructor(private http: HttpClient) {
  }

  getRucInfo(ruc){
    return new Promise((resolve, reject) => {
      this.http.get(this.cors + 'https://api.sunat.cloud/ruc/' + ruc)
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  getRextie(){
    return new Promise( (resolve, reject) => {
      this.http.post(this.cors + 'https://app.rextie.com/api/v1/fxrates/rate/?origin=home', {
        promo_code: null,
        source_amount: 1000,
        source_currency: "USD",
        target_currency: "PEN"
      })
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  getAcomo(){
    return new Promise( (resolve, reject) => {
      this.http.get('https://acomo.com.pe/api/current_change')
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getKambista(){
  	return new Promise( (resolve, reject) => {
      this.http.get('https://api.kambista.com/v1/utils/exchange')
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  getTuCambista(){
    return new Promise((resolve, reject) => {
      this.http.get(this.cors + 'https://app.tucambista.pe/api/transaction/getquote/2000/USD/BUY')
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  getInstaKash() {
    return new Promise((resolve, reject) => {
      this.http.get(this.cors + 'https://api.instakash.net/exchange-service/api/v1/client/rates')
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  getTkambio() {
    return new Promise((resolve, reject) => {
      this.http.post(this.cors + 'https://tkambio.com/wp-admin/admin-ajax.php?action=get_exchange_rate', {})
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

}