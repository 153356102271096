import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { DecamoneyService } from 'src/app/providers/decamoney.service';
import * as industry_list from '../../../providers/_industry-list';

@Component({
  selector: 'app-company-create',
  templateUrl: './company-create.component.html',
  styleUrls: ['./company-create.component.css']
})
export class CompanyCreateComponent implements OnInit {

  public companyCreated: boolean;

  public _user_id: string;
  public _user_email: string;

  public _industry_list;

  public stakeHolder = {
    name: '',
    doc_type: '',
    doc_number: '',
    participation: ''
  }

  public company = {
    ceo: [{ name: '', doc_type: '', doc_number: '' }],
    name: '',
    phone: '',
    ruc: '',
    email: '',
    industry: '',
    stakeholders: [],
    roles_attributes: [{ user_id: '', position: '', email: '' }]
  }

  constructor(
    private routeParams: ActivatedRoute,
    private toastr: ToastrService,
    private location: Location,
    private decamoney: DecamoneyService,
  ) { }

  ngOnInit() {
    this.routeParams.params
      .subscribe( params => {
        if (params) {
          this._industry_list = industry_list.data
          this._user_id = params.user_id
          this._user_email = params.user_email
          this.company.roles_attributes[0].user_id = this._user_id
          this.company.roles_attributes[0].email = this._user_email
        }
      })
  }

  back() {
    this.location.back();
  }

  addStakeholder(name: string, id: string, number: string, participation: string) {
    if (!name) return this.toastMessage('Completa el nombre del accionista')
    if (!id) return this.toastMessage('Completa el tipo de documento del accionista')
    if (!number) return this.toastMessage('Completa el numero de documento del accionista')
    if (!participation) return this.toastMessage('Completa el porcentaje del accionista')
    if (name && id && number && participation) {
      this.company.stakeholders.push({
        name: name,
        doc_type: id,
        doc_number: number,
        participation: participation
      })
      this.toastr.success('Accionista agregado correctamente', '', {
        timeOut: 3000,
      })
      this.stakeHolder.name = this.stakeHolder.doc_type = this.stakeHolder.doc_number = this.stakeHolder.participation = ''
    }
  }

  createCompany() {
    if (!this.company.name) return this.toastMessage('Completa el nombre de la empresa');
    if (!this.company.ruc) return this.toastMessage('Completa el RUC de la empresa');
    if (!this.company.phone) return this.toastMessage('Completa el número de telefono de la empresa');
    if (!this.company.email) return this.toastMessage('Completa el correo electrónico de la empresa');
    if (!this.company.industry) return this.toastMessage('Completa la industria de la empresa');
    if (!this.company.ceo[0].name) return this.toastMessage('Completa el nombre del CEO de la empresa');
    if (!this.company.ceo[0].doc_type) return this.toastMessage('Completa el tipo de documento del CEO de la empresa');
    if (!this.company.ceo[0].doc_number) return this.toastMessage('Completa el número de documento del CEO de la empresa');
    if (!this.company.roles_attributes[0].position) return this.toastMessage('Completa el cargo en la empresa que tiene el usuario');
    if (this.company.name && this.company.ruc && this.company.phone && this.company.email && this.company.industry && this.company.ceo[0].name && this.company.ceo[0].doc_type && this.company.ceo[0].doc_number && this.company.roles_attributes[0].position) {
      this.decamoney.createCompany(this.company)
      .then((res) => {
        if (res) {
          this.toastr.success(`La empresa ${this.company.name}, fue creada correctamente`, '', {
            timeOut: 3000,
          });
          this.location.back();
        }
      }, (err) => {
        err ? this.toastMessage(`Hubo un error al crear la empresa, error : ${err}`) : '';
      })
    }
  }

  toastMessage(msg: string) {
    return this.toastr.error(`${msg}`, '', {
      timeOut: 3000,
    });
  }
}
