import { Component, OnInit } from '@angular/core';
import { BitcoinService } from '../../providers/bitcoin.service';

@Component({
  selector: 'app-bitcoin',
  templateUrl: './bitcoin.component.html',
  styleUrls: ['./bitcoin.component.css']
})
export class BitcoinComponent implements OnInit {
	public btcprices = {
		coindesk: 0,
		blockchain: 0,
		coinmarket: 0
	}

  public fee = {
    buy_fee: 0.07,
    sell_fee: 0.07
  }

  public bitcoin = {
    buy: 0,
    sell: 0
  }

  constructor(public btcService: BitcoinService) { }

  ngOnInit() {
  	this.getPrices();
  }


  getPrices(){
  	this.btcService.coinDeskUSD()
  		.then((res) => {
  			this.btcprices.coindesk = res['bpi']['USD']['rate_float'];
  			this.btcService.getBlockChain()
  				.then((res) => {
  					this.btcprices.blockchain = res['USD']['last'];
  					this.btcService.getCoinMarket()
  						.then((res) => {
  							this.btcprices.coinmarket = res['data'][0]['quote']['USD']['price'];
  							this.getProm();
  						}); 
  				});
  		});
  }

  getProm(){
  	let zero = 0;
  	for (let i in this.btcprices) {
  		zero+=this.btcprices[i];
  	}
  	return zero/3;
  }

}