import { Component, OnInit, ViewChild } from '@angular/core';
import { DecamoneyService } from '../../providers/decamoney.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as moment from 'moment';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.css']
})
export class CouponsComponent implements OnInit {
  @ViewChild('template') template;
  @ViewChild('editModal') editModal;
  modalRef: BsModalRef;
  public selectedItem;
	public coupon = {
		code: '',
    activate: false,
    exchange_rate: null,
		fee: 0,
		description: '',
    expire_at: ''
	};
  public allCoupons:any = [];
  public expiredCoupons:any = [];
  public editCoupon:any;
  constructor(
    public decamoney: DecamoneyService,
    public toastCtrl: ToastrService,
    private modalService:BsModalService) { }

  ngOnInit() {
    this.decamoney.getAllCoupons()
      .then((res:any) => {
        let withExpiration = res.filter(c => c.expire_at != null);
        for (let i = 0; i < withExpiration.length; i++) {
          let x = moment(withExpiration[i].expire_at);
          if (moment().diff(x, 'seconds') > 86400) {
            this.expiredCoupons.push(withExpiration[i]);
          }
        }
        this.allCoupons = res.filter( ( el ) => !this.expiredCoupons.includes(el) );
        this.sortArray();
      });
  }

  sortArray(){
    this.allCoupons.sort((a,b):any => {
      if (a.expire_at < b.expire_at) return -1;
      else if (a.expire_at > b.expire_at) return 1;
      else if (a.expire_at === null) return 1;
      else if (b.expire_at === null) return -1;
      return 0;
    });
  }

  addOneDay(date: Date){
    if (date) {
      let abc = new Date(date);
      return abc.setDate(abc.getDate() + 1);
    }
  }

  create(coupon){
    let y = new Date(coupon.expire_at);
    let endDate = new Date(y.getFullYear(), y.getMonth(), y.getDate(), 23, 59, 59);
    coupon.expire_at = moment(endDate).toISOString(true);
  	this.decamoney.createCoupon(coupon)
  		.then((res) => {
        this.allCoupons.push(res);
        this.coupon = {
          code: '',
          activate: false,
          exchange_rate: null,
          fee: 0,
          description: '',
          expire_at: ''
        };
        this.toastCtrl.success('Cupón creado');
        this.sortArray();
  		}, (err) => {
        this.toastCtrl.error(err.error);
      });
  }

  edit(coupon){
    let x = moment(coupon.expire_at);
    coupon.expire_at = x.endOf('day').toISOString();
    this.decamoney.editCoupon(coupon)
      .then((res) => {
        this.modalRef.hide();
        this.toastCtrl.success('Cupón editado correctamente');
        this.sortArray();
      }, (err) => {
        this.toastCtrl.error(err.error);
      });
  }

  showModal(item){
    this.modalRef = this.modalService.show(this.template, {class: 'modal-dialog-centered'});
    this.selectedItem = item;
  }

  openEditModal(item) {
    this.modalRef = this.modalService.show(this.editModal, {class: 'modal-lg'});
    this.editCoupon = item;
    this.editCoupon.activate = item.exchange_rate?true:false;
  }

  deleteType(){
    this.decamoney.deleteCoupon(this.selectedItem.id)
      .then((res) => {
        let index = this.allCoupons.indexOf(this.selectedItem);
        this.allCoupons.splice(index, 1);
        this.toastCtrl.warning('Cupón Eliminado!');
        this.modalRef.hide();
      });
  }

}