import { Component, OnInit } from '@angular/core';

import { RateMicroserviceService } from 'src/app/providers/rate-microservice.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-market-notifications',
  templateUrl: './market-notifications.component.html',
  styleUrls: ['./market-notifications.component.css']
})


export class MarketNotificationsComponent implements OnInit {

  bot_status: boolean
  time: string
  sensitivity: string
  weights = {
    acomo: '',
    instakash: '',
    kambista: '',
    rextie: '',
    tkambio: '',
    tucambista: ''
  }
  constructor(
    public rate_service: RateMicroserviceService,
    public toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.rate_service.getStatus()
      .then((_res) => {
        this.bot_status = _res == 'The rate notifications are on' ? true : false
      })
    this.rate_service.getTime()
      .then((res: string) => {
        this.time = res.match(/\d/g).join("")
      })
    this.rate_service.getSensitivity()
      .then((res: string) => {
        this.sensitivity = res
      })
    this.rate_service.getWeights()
      .then((res: string) => {
        this.weights = JSON.parse(res)
      })
  }

  ngOnChanges() {
    if (this.bot_status) {
      this.rate_service.updateStatus('false')
        .then((_res) => {
          if (_res == 'Notifications are off') { this.bot_status = false }
          this.toastr.success('Las notificaciones de mercado fueron apagadas', '', {
            timeOut: 3000
          })
        }, err => {
          this.toastr.error('Hubo un error al apagar las notificaciones', '', {
            timeOut: 3000
          })
        })
    } else {
      this.rate_service.updateStatus('true')
        .then((_res) => {
          if (_res == 'Notifications are on') { this.bot_status = true }
          this.toastr.success('Las notificaciones de mercado fueron prendidas', '', {
            timeOut: 3000
          })
        }, err => {
          this.toastr.error('Hubo un error al prender las notificaciones', '', {
            timeOut: 3000
          })
        })
    }
  }

  update_time(){
    if (Number.isInteger(Number(this.time)) && Number(this.time) != 0) {
      this.rate_service.updateTime(this.time)
        .then((res: string) => {
          if (res == 'The notifications are off, but the interval time have been updated') { 
            this.toastr.success(`Las notificaciones estan apagadas, pero el tiempo fue actualizado a ${this.time} minutos`, '', {
              timeOut: 3000
            })
          } else {
            this.time = res.match(/\d/g).join("")
            this.toastr.success(`Se actualizo correctamente el valor, las notificaciones se mostraran cada ${this.time} minutos`, '', {
              timeOut: 5000
            })
          }
        }, err => {
          this.toastr.error('Hubo un error al actualizar el tiempo de las notificaciones', '', {
            timeOut: 3000
          })
        })
    } else {
      this.toastr.error('Ingresa un valor correcto, los números deben ser enteros', '', {
        timeOut: 3000
      })
    }
  }

  update_sensitivity() {
    if (Number.isInteger(Math.floor(Number(this.sensitivity)))) {
      this.rate_service.updateSensitivity(this.sensitivity)
        .then((res: string) => {
          if (res == 'The notifications are off, but the sensitivity have been updated') {
            this.toastr.success(`Las notificaciones estan apagadas, pero la sensibilidad fue actualizada a ${this.sensitivity}`, '', {
              timeOut: 3000
            })
            return
          }
          if (Number(res) == Number(this.sensitivity)) {
            this.toastr.success(`Se actualizo correctamente el valor, la sensibilidad actual es ${this.sensitivity}`, '', {
              timeOut: 5000
            })
            return
          }
        }, err => {
          this.toastr.error('Hubo un error al actualizar la sensibilidad', '', {
            timeOut: 3000
          })
        })
    } else {
      this.toastr.error('Ingresa un valor correcto, solo puedes ingresar números y los decimales tienen que estar separado por un punto', '', {
        timeOut: 5000
      })
    }
  }

  update_weights() {
    if (
      Number.isInteger(Math.floor(Number(this.weights.acomo))) &&
      Number.isInteger(Math.floor(Number(this.weights.instakash))) && 
      Number.isInteger(Math.floor(Number(this.weights.kambista))) && 
      Number.isInteger(Math.floor(Number(this.weights.rextie))) && 
      Number.isInteger(Math.floor(Number(this.weights.tkambio))) && 
      Number.isInteger(Math.floor(Number(this.weights.tucambista)))
    ) {
      this.rate_service.updateWeights(this.weights)
        .then((res: string) => {
          if (res == 'The notifications are off, but the weights have been updated') {
            this.toastr.success('Las notificaciones estan apagadas, pero los pesos fueron actualizados', '', {
              timeOut: 3000
            })
          }
          this.toastr.success('Se actualizo correctamente los pesos', '', {
            timeOut: 3000
          })
          console.log(res)
          return
        }, err => {
          this.toastr.error('Hubo un error al actualizar los pesos', '', {
            timeOut: 3000
          })
        })
    } else {
      this.toastr.error('Ingresa valores correctos, solo puedes ingresar números y los decimales tienen que estar separado por un punto', '', {
        timeOut: 5000
      })
    }
  }
}
