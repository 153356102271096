import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DecamoneyService } from 'src/app/providers/decamoney.service';
import { LoaderEvent } from 'src/app/providers/loader-event';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  public id;
  public company:any = {
    ceo: [{ name: '', doc_type: '', doc_number: '' }],
    name: '',
    phone: '',
    ruc: '',
    email: '',
    industry: '',
    stakeholders: [],
    bank_accounts: [],
    roles: [{ user_id: '', position: '', email: '' }],
    accounts: [],
    address_country: '',
    address_district: '',
    address_province: '',
    address_region: '',
    address_street1: '',
    address_street2: '',
  }
  public user:any;
  constructor(
    public decamoney : DecamoneyService,
    public route : ActivatedRoute,
    public router : Router,
    public location : Location,
    private loader: LoaderEvent,
  ) { 
    this.loader.fire(true);
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.decamoney.getCompany(this.id)
      .then((res) => {
        this.company = res;
        this.decamoney.getUser(this.company.roles[0].user_id)
          .then((res) => {
            this.user = res;
          })
        this.loader.fire(false);
      })
  }

  back() {
    this.location.back();
  }

}
