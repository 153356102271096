import { Injectable } from '@angular/core';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import * as fs from 'file-saver';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
	public date = moment().format("dddd, Do MMMM YYYY");
  constructor() { }

  generateExcel(title, headers, data){
  	// Create workbook and worksheet
	let workbook:ExcelProper.Workbook = new Excel.Workbook();
	let worksheet = workbook.addWorksheet('Example');

	//Add Title Row and formatting
	let titleRow = worksheet.addRow([`${title}  ${this.date}`]);
	titleRow.font = {name: 'Dosis', size: 16, bold: true};

	//Add Header Row
	let headerRow = worksheet.addRow(headers);
	headerRow.eachCell((cell, number) => {
		cell.fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: { argb: 'FF2B253D'},
			bgColor: { argb: 'FFFFFFFF'}
		};
		cell.border = { top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: { style: 'thin'} };
		cell.font = { color: { argb: 'FFFFFFFF'}};
	})

	data.forEach(d => {
		let row = worksheet.addRow(d);
	});
	worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 25;
    worksheet.getColumn(5).width = 25;
	//Generate Excel File
	workbook.xlsx.writeBuffer()
		.then((data:any) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
			fs.saveAs(blob, title + '.xlsx');
		});
  }
}
