import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DecamoneyService } from 'src/app/providers/decamoney.service';

@Component({
  selector: 'app-config-options',
  templateUrl: './config-options.component.html',
  styleUrls: ['./config-options.component.css']
})
export class ConfigOptionsComponent implements OnInit {
  modalRef: BsModalRef;
  public new_value;
  public config = 
  {
    created_at: '',
    id: 0,
    parameter: '',
    updated_at: '',
    value: ''
  }
  @ViewChild('msgModal') msgModal;
  public configList:Array<any> = [{}];
  constructor(public decamoneyS: DecamoneyService,
              private modalService:BsModalService,
              public toastCtrl: ToastrService) { }
 
  ngOnInit() {
    this.decamoneyS.getConfig().then((res:any)=> {
      this.configList = res;
      console.log(this.configList)
    });
  }

  newValue (event: any) {
    this.new_value = event.target.value;
  }

  openModal(){
    this.modalRef = this.modalService.show(this.msgModal);
  }

  create(){
    this.decamoneyS.createConfig(this.config).then((res)=> {
      this.ngOnInit();
    });
    console.log(this.config);
  }

  edit(id, parameter){
    this.decamoneyS.editConfig(id, { parameter: parameter, value: this.new_value})
      .then((res) => {
        this.ngOnInit();
        this.toastCtrl.success('Parametro Actualizado');
      });
  }

  delete(id){
    this.decamoneyS.deleteConfig(id).then((res) => {
      this.ngOnInit();
      this.toastCtrl.success('Parametro Eliminado');
    });
  }

}
