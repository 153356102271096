import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderEvent } from '../../../providers/loader-event';
import { DecamoneyService } from '../../../providers/decamoney.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as moment from 'moment';
import * as countries from '../../../providers/countries';
import * as jobs from '../../../providers/jobs';
import * as pepRelationship from '../../../providers/_pep-relationship-list';
import * as pepJobs from '../../../providers/_pep-jobs';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {
  @ViewChild('verify') verify;
  @ViewChild('beingdeleted') beingdeleted;
  modalRef: BsModalRef;
	public sub;
  public allCountries;
  public allJobs;
  public allPepRelatioship;
  public allPepJobs;
  public _id;
  public creating: Boolean = true;
  public newUser: Boolean = true;
  public userCreated: Boolean = false;
  public pep: Boolean = false;

  public user = {
      name: '',
      lastname1: '',
      lastname2: '',
      email: '',
      phone: '',
      password: '',
      job: '',
      doc_type: '',
      doc_number: '',
      country: '',
      birthdate: '',
  }

  public userPep = {
		pep_type: 'titular',
		relationship: '',
		job_title: '',
		fullname: '',
		organization: '',
		start_at: '',
		finish_at: '',
		now: false
	};

  public userForm:any = {
    name: '',
    lastname1: '',
    lastname2: '',
    email: '',
    doc_number: '',
    job: '',
    pep: '',
    birth: '',
    expiration: '',
    is_verified: null,
    phone: '',
    phone2: '',
    phone3: '',
    phone4: '',
    bank_accounts: [],
    pep_field: []
  }

  constructor(
    private router: Router,
    private routeParams: ActivatedRoute,
    private loader: LoaderEvent,
    private modalService:BsModalService,
    public toastr: ToastrService,
    private location: Location,
    private decamoney: DecamoneyService
  ) {
    this.loader.fire(true);
  }


  ngOnInit() {
  	this.sub = this.routeParams.params.subscribe( 
  		params => {
        if (params.new) {
          this.loader.fire(false);
          this.allCountries = countries.data;
          this.allJobs = jobs.data;
          this.allPepRelatioship = pepRelationship.data;
          this.allPepJobs = pepJobs.data;
        } else {
          this.newUser = false;
          this.allCountries = countries.data;
          this._id = params.id;
          this.creating = false;
          this.decamoney.getUser(this._id)
            .then((res) => {
              this.userForm = res;
              this.userForm.pep_field = this.userForm.pep?this.userForm.pep[this.userForm.pep.length - 1]:[];
              this.userForm.pep = this.userForm.pep?this.userForm.pep[this.userForm.pep.length - 1]['pep_type']:'No';
              this.loader.fire(false);
            });
        }
  		}
  	)
  }

  cciformat(number) {
    if (number.length < 20) {
      return number
    }
    let ent = number.substr(0,3);
    let ofc = number.substr(3,3);
    let account = number.substr(6,12);
    let cc = number.substr(18,2);
    return `${ent}-${ofc}-${account}-${cc}`
  }

  onSubmit(form) {
  }

  back() {
    this.location.back();
  }

  isVerified(){
    this.modalRef = this.modalService.show(this.verify, {class: 'modal-dialog-centered'});
  }

  checkUser(){
    this.userForm.is_verified = true;
    this.decamoney.updateUser(this._id, this.userForm)
      .then((res) => {
        this.toastr.success('Usuario verificado y actualizado correctamente');
        this.modalRef.hide();
      });
  }

  edit(){
    this.decamoney.updateUser(this._id, this.userForm)
      .then((res) => {
        this.toastr.success('Usuario actualizado correctamente');
        this.modalRef.hide();
      });
  }

  deleteModal(){
    this.modalRef = this.modalService.show(this.beingdeleted, { class: 'modal-dialog-centered'});
  }

  deleteUser(){
    this.decamoney.deleteUser(this._id)
      .then((res) => {
        this.toastr.success('Usuario eliminado correctamente');
        this.modalRef.hide();
      }, (err) => {
        console.log(err.error);
        if (err.error == "User's account has available balance, cannot be deleted") {
          this.toastr.warning('El usuario aún tiene saldo en sus cuentas');
        } else {
          this.toastr.error('Error al eliminar usuario');
        }
      });
  }

  createNewUser() {
    this.decamoney.createUser(this.user)
      .then((res:any) => {
        this.toastr.success('Usuario creado correctamente', '', {
          timeOut: 3000,
        });
        this.router.navigate(['/users']);
      }, (err) => {
        this.toastr.error('Error al crear al usuario', '', {
          timeOut: 3000,
        });
      })
  }
  
  /*
  createNewUser() {
    this.decamoney.createUser(this.user)
      .then((res:any) => {
        this._id = res.id;
        this.userForm = res
        this.userForm.pep_field = this.userForm.pep?this.userForm.pep[this.userForm.pep.length - 1]:[];
        this.userForm.pep = this.userForm.pep?this.userForm.pep[this.userForm.pep.length - 1]['pep_type']:'No';
        this.toastr.success('Usuario creado correctamente');
        this.userCreated = true;
        console.log(this.userForm)
      }, (err) => {
        this.toastr.error('Error al crear al usuario')
      })
  }

  updateUserPep() {
    this.userForm.pep_type = this.userPep
    setTimeout(() => {
      this.decamoney.updateUser(this._id, this.userForm)
        .then((res) => {
          console.log(res)
          this.toastr.success('Usuario actualizado correctamente');
          this.modalRef.hide();
        });
    }, 500)
  }

  isPep(val) {
    this.pep = val ? true : false;
  }

  pepType(val) {
    switch(val) {
      case 'titular':
        this.userPep.pep_type = 'titular'
        break;
      case 'familiar':
        this.userPep.pep_type = 'familiar'
        break;
      default:
        this.userPep.pep_type = 'colaborador'
        break;
    }
  }

  pepDate(val) {
    this.userPep.now = !val
    this.userPep ? this.userPep.finish_at = '' : '';
  }*/
}