import { Component, OnInit } from '@angular/core';
import { ExternaldataService } from '../../providers/externaldata.service';
import { DecamoneyService } from '../../providers/decamoney.service';
import { LoaderEvent } from '../../providers/loader-event';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-datamarket',
  templateUrl: './datamarket.component.html',
  styleUrls: ['./datamarket.component.css']
})
export class DatamarketComponent implements OnInit {
	public totalData = [];
  public banks = [];
  public others = [];
  public middle;
  constructor(
    public external: ExternaldataService,
    public toastr: ToastrService,
    public _loader: LoaderEvent,
    public decamoney: DecamoneyService) {
    this._loader.fire(true);
  }

  ngOnInit() {
    let formDataTk = new FormData();
    formDataTk.append('action', 'get_tipo_cambio');
    this.loadExternalData();
  }

  async loadExternalData () {
    await this.external.getRextie().then((res:any) => {
      this.toData(this.totalData, 'Rextie', res.fx_rate_buy, res.fx_rate_sell);
    }, (err)=> {
      this.inError(err);
    });
    await this.external.getKambista().then((res:any) => {
      this.toData(this.totalData, 'Kambista', res.bid, res.ask);
    }, (err)=> {
      this.inError(err);
    });
    await this.external.getTuCambista().then((res:any) => {
      this.toData(this.totalData, 'TuCambista', res.buyExchangeRate, res.sellExchangeRate);
    }, (err)=> {
      this.inError(err);
    });
    await this.external.getAcomo().then((res:any) => {
      this.toData(this.totalData, 'Acomo', res.BID, res.OFFER);
    });
    await this.external.getInstaKash().then((res:any) => {
      this.toData(this.totalData, 'InstaKash', res[0].buy, res[0].sell);
    }, (err)=> {
      this.inError(err);
    });
    await this.external.getTkambio().then((res:any) => {
      this.toData(this.totalData, 'Tkambio', res.buying_rate, res.selling_rate);
    }, (err)=> {
      this.inError(err);
    });
    this.generateProm();
  }

  inError(err){
    this.toastr.error(err, 'Error', {
      timeOut: 2500
    });
    this._loader.fire(false);
  }

  generateProm(){
    let buyProm = 0;
    let sellProm = 0;
    this.totalData = this.totalData.filter(data => data.tc_buy != 0);
    for (var i = 0; i < this.totalData.length; i++) {
      buyProm += Number(this.totalData[i].tc_buy);
      sellProm += Number(this.totalData[i].tc_sell);
    }
    this.toData(this.totalData, 'Promedio', buyProm/this.totalData.length, sellProm/this.totalData.length);
    this.decamoney.getRates().then((res:any) => {
      this.toData(this.totalData, 'Decamoney', res['exchange_rate'].buy, res['exchange_rate'].sell);
      this._loader.fire(false);
    });
  }

  toData(array:Array<any>,name, buy, sell){
  	array.push({name: name, tc_buy: buy, tc_sell: sell, mid: Number(buy) + Number(sell), diff: Number(sell)/ ((Number(buy) + Number(sell))/2)})
  }
}