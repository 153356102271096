import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecamoneyService } from '../../providers/decamoney.service';
import { LoaderEvent } from '../../providers/loader-event';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public  active_users= {total: 0, last_year: 0, last_month: 0, last_week: 0};
  public  amounts= {total: '', last_year: '', last_month: '', last_week: ''};
  public  transactions= {total: 0, last_year: 0, last_month: 0, last_week: 0};
  public  users= {total: 0, last_year: 0, last_month: 0, last_week: 0};
  public test;
  constructor(private router: Router,
    public loader: LoaderEvent,
    public decamoneyS: DecamoneyService) {
  }

  ngOnInit() {
    this.loader.fire(true);
    this.decamoneyS.getReport().then((res)=> {
      this.active_users = res['active_users']
      this.amounts = res['amounts']
      this.transactions = res['transactions']
      this.users = res['users']
      this.loader.fire(false);
      this.amounts.total = this.numberWithCommas(this.amounts.total)
      this.amounts.last_year = this.numberWithCommas(this.amounts.last_year)
      this.amounts.last_month = this.numberWithCommas(this.amounts.last_month)
      this.amounts.last_week = this.numberWithCommas(this.amounts.last_week)
    });
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}