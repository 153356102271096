import { Component, OnInit } from '@angular/core';
import { DecamoneyService } from '../../../providers/decamoney.service';
import { LoaderEvent } from '../../../providers/loader-event';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>;
  public orders:Array<any> = [];
  public accountsDeca:Array<any> = [{},{}];
  public search:boolean = false;
  public querySearch;
  public currentSearch:Array<any> = [];
  public usersTotals = {
    depPEN: 0,
    depUSD: 0,
    retPEN: 0,
    retUSD: 0
  };
  public totalData = {
    deposits: [],
    withdrawals: [],
    purchase: [],
    sale: [],
    sameCurrency: []
  };
  public allData;
  public showTable:boolean = false;
  public filter: any = { status: 'pending', destination: { currency: '' }};
  public dateRange = [];
  public currencyRange = {
    min: 1,
    max: 99999
  };
  public actualTab;
  public types:Array<string> = ['deposits','withdrawals','transfers'];
  constructor(
    public decamoneyS: DecamoneyService,
    public loader: LoaderEvent) {
      this.loader.fire(true);
    }

  ngOnInit() {
    this.decamoneyS.dataDecamoney()
      .then((res) => {
        this.accountsDeca = res['accounts'].sort((a,b):any => {
          if (a.account < b.account) return -1;
          else if (a.account > b.account) return 1;
          return 0;
        });
      });
    this.loadOrdersType('deposits', 1);
    this.loadOrdersType('withdrawals', 1);
  }

  change(data, type?) {
    let temp = data;
    if (this.filter.status) {
      temp = temp.filter(ord => ord.status == this.filter.status);
    }
    if (this.dateRange.length > 0) {
      temp = temp.filter(ord => moment(ord.created_at) >= moment(this.dateRange[0]).startOf('day') && moment(ord.created_at).startOf('day') <= moment(this.dateRange[1]));
    }
    if (type) {
      this.currentSearch[type] = temp;
    } else {
      this.currentSearch = temp;
    }
  }

  newSearch(){
    this.search = true;
    if (!this.actualTab) {
      this.actualTab = this.totalData.deposits;
    }
    if (this.actualTab == this.totalData.purchase || this.actualTab == this.totalData.sale) {
      this.currentSearch['purchase'] = this.totalData.purchase.filter(dep => Number(dep.destination.amount) >= this.currencyRange.min && Number(dep.destination.amount) <= this.currencyRange.max);
      this.currentSearch['sale'] = this.totalData.sale.filter(dep => Number(dep.origin.amount) >= this.currencyRange.min && Number(dep.origin.amount) <= this.currencyRange.max);
    } else {
      this.currentSearch = this.actualTab.filter(dep => Number(dep.destination.amount) >= this.currencyRange.min && Number(dep.destination.amount) <= this.currencyRange.max);
    }
    if (this.dateRange[0] && this.dateRange[1]) {
      if (this.currentSearch['purchase']) {
        this.change(this.currentSearch['purchase'], 'purchase');
        this.change(this.currentSearch['sale'], 'sale');
      } else {
        this.change(this.currentSearch);
      }
    }
  }

  cancelSearch(){
    this.search = false;
    this.dateRange = [];
  }

  loadOrders() {
    this.loadOrdersType('deposits');
    this.loadOrdersType('withdrawals');
    this.loadOrdersType('transfers');
    setTimeout(() => {
      this.allData = JSON.parse(JSON.stringify(this.totalData));
      this.loader.fire(false);
    }, 3000)
  }

  statuschange(e) {
    for (let k in this.totalData) {
      this.totalData[k].sort((a,b):any => {
        if (e == 'pending' || e == 'hold') {
          return +new Date(a.created_at) - +new Date(b.created_at);
        } else {
          return +new Date(b.created_at) - +new Date(a.created_at);
        }
      });
    }
  }

  countPendings(array) {
    return array.filter(ord => ord.status == 'pending').length
  }

  countWithStatus(orders) {
    if (this.filter.status == '') {
      return -1
    }
    return orders.filter(order => order.status == this.filter.status).length;
  }

  sum(arr, af?) {
    let result = 0;
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].status == 'pending') {
        if (af) {
          console.log(result);
        }
        arr[i].sum = result + Number(arr[i].destination.amount);
        result += Number(arr[i].destination.amount);
      }
    }
  }

  loadOrdersType(type, page?) {
    this.decamoneyS.getOrders(type, page).then( (res) => {
      if (type == 'transfers') {
        for (var i = res['orders'].length - 1; i >= 0; i--) {
          if (res['orders'][i].origin.currency == 'PEN' && res['orders'][i].destination.currency == 'USD') {
            this.totalData.purchase.push(res['orders'][i]);
          } else if (res['orders'][i].origin.currency == 'USD' && res['orders'][i].destination.currency == 'PEN') {
            this.totalData.sale.push(res['orders'][i]);
          } else {
            this.totalData.sameCurrency.push(res['orders'][i]);
          }
        }
        this.statuschange(this.filter.status);
        this.sum(this.totalData.purchase, false);
        this.sum(this.totalData.sale, true);
      } else {
        this.statuschange(this.filter.status);
        this.totalData[type] = this.totalData[type].concat(res['orders'].reverse());
      }
      page++;
      if (res['orders'].length >= 100) {
        this.loadOrdersType(type, page);
      }
      this.loader.fire(false);
    }, (err) => {
      this.statuschange(this.filter.status);
      this.loader.fire(false);
    });
  }

  calculateSumPen(array){
    let pen = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i].destination.currency == 'PEN') {
        pen += Number(array[i].destination.amount);
      }
    }
    return pen;
  }

  calculateSumUsd(array){
    let usd = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i].destination.currency == 'USD') {
        usd += Number(array[i].destination.amount);
      }
    }
    return usd;
  }

  calculateSearchLength(array){
    if (array.purchase && array.sale) {
      let tempA = this.filter.status?array.purchase.filter(arr => arr.status == this.filter.status):array;
      let tempB = this.filter.status?array.sale.filter(arr => arr.status == this.filter.status):array;
      if (this.filter.destination.currency) {
        let temp2 = tempA.filter(tem => tem.destination.currency == this.filter.destination.currency);
        let temp2B = tempB.filter(tem => tem.destination.currency == this.filter.destination.currency);
        return temp2.concat(temp2B);
      }
      return tempA.concat(tempB);
    } else {
      let temp = this.filter.status?array.filter(arr => arr.status == this.filter.status):array;
      if (this.filter.destination.currency) {
        let temp2 = temp.filter(tem => tem.destination.currency == this.filter.destination.currency);
        return temp2
      }
      return temp;
    }
  }

  onChangeTab(a, b) {
    this.search = false;
    this.loader.fire(true);
    this.totalData = {
      deposits: [],
      withdrawals: [],
      purchase: [],
      sale: [],
      sameCurrency: []
    };
    if (b==3) {
      this.actualTab = this.totalData[Object.keys(this.totalData)[4]];
    } else {
      this.actualTab = this.totalData[Object.keys(this.totalData)[b]];
    }
    this.loadOrdersType(this.types[Object.keys(this.types)[b==3?b=2:b]], 1);
    if (a.textLabel == 'Transferencias') {
      this.showTable = true;
    } else {
      this.showTable = false;
    }
  }

  countTotal(pen) {
    let depo = this.newFilter(this.totalData['deposits'], pen?'PEN':'USD');
    let ret = this.newFilter(this.totalData['withdrawals'], pen?'PEN':'USD');
    return depo - ret;
  }

  newFilter(array:Array<any>, currency) {
    return array.filter(arr => arr.destination.currency == currency && arr.status == 'completed')
    .map(c => Number(c.destination.amount))
    .reduce((sum, current) => sum + current, 0);
  }
}