import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BitcoinService {
  headers:HttpHeaders;
  cors = 'https://cors-anywhere.herokuapp.com/';
  key = '121e635d-ced9-4f05-804b-ca2fe62f846d';

  constructor(private http: HttpClient) {
  }

  setHeaders(){
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
	  'Access-Control-Allow-Headers': 'Content-Type',
	  'X-CMC_PRO_API_KEY': this.key
    });
  }

  
  coinDesk(){
    return new Promise( (resolve, reject) => {
      this.http.get('https://api.coindesk.com/v1/bpi/currentprice.json')
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  coinDeskUSD(){
  	return new Promise( (resolve, reject) => {
      this.http.get('https://api.coindesk.com/v1/bpi/currentprice/USD.json')
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  getCoinMarket(){
  	this.setHeaders();
  	return new Promise( (resolve, reject) => {
      this.http.get(this.cors + 'https://pro-api.coinmarketcap.com/v1/cryptocurrency/listings/latest?limit=1', { headers: this.headers})
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  getBlockChain(){
  	return new Promise((resolve, reject) => {
  		this.http.get('https://blockchain.info/ticker')
  			.subscribe(res => {
  				resolve(res);
  			}, (err) => {
  				reject(err);
  			});
  	});
  }



}