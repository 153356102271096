import { Component, OnInit, TemplateRef, Output, EventEmitter } from '@angular/core';
import { RouterModule, Routes, Router, NavigationEnd } from '@angular/router';
import { SessionService } from '../../providers/session.service';
import { SendServiceService} from '../../providers/send-service.service';
import { DecamoneyService } from '../../providers/decamoney.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() onFilter = new EventEmitter();
  public pendings:number = 0;
  public tc = {
    buy: 0,
    sell: 0
  };
  public env = environment.name;
  constructor(
  	public session: SessionService,
  	public router: Router,
    private sendServ:SendServiceService,
    private decaServ: DecamoneyService
  	) {
      this.sendServ.listen().subscribe((val) => {
        if (val == 'newTC') {
          this.getExchangeRate();
        }
      });
    }

  ngOnInit() {
    if (this.session.getObject('decamoney_admin')) {
      this.getExchangeRate();
      this.loadPendings();
    }
  }

  reload(){
    this.getExchangeRate();
    this.loadPendings();
  }

  getExchangeRate(): void {
    this.decaServ.getAdminRates()
    .then((res:any) => {
      let middle = res.filter(tc => tc.currency == 'PEN')[0].middle;
      this.decaServ.getFees()
        .then(res => {
          this.tc.buy = middle * (1 + res[0].fee.fee);
          this.tc.sell = middle * (1 - res[1].fee.fee);
        });
    });
  }


  loadPendings(){
    if (this.router.url != '/login') {
      this.pendings = 0;
      this.decaServ.getPending().then(res => {
        this.pendings = res['pending']['total'];
      }, (err) => {
        console.log("Error loading counter for pending orders");
      });
    }
  }

  logout() {
  	this.sendServ.filter('showLogout');
  }
}