import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-notifications-create',
  templateUrl: './notifications-create.component.html',
  styleUrls: ['./notifications-create.component.css']
})
export class NotificationsCreateComponent implements OnInit {
	public noti = {
		title: '',
		message: '',
		created_at: '',
		expiration: '',
		grade: '',
		justOnce: ''

	};
  constructor(public location: Location) { }

  ngOnInit() {
  }

  back() {
    this.location.back();
  }
}