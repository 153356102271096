import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DecamoneyService } from '../../../providers/decamoney.service';
import { PARAMETERS } from '@angular/core/src/util/decorators';

@Component({
  selector: 'app-user-bank',
  templateUrl: './user-bank.component.html',
  styleUrls: ['./user-bank.component.css']
})
export class UserBankComponent implements OnInit {
	public sub;
	public account:any = {
		currency: '',
		bank_name: '',
		cci_number: ''
	};
	public userId;
	public decaAccount;
	public movements = [];

	public type: string;
	public id: any;
	public name: any;
	public company: any;
	public user:any;
  constructor(public location: Location,
  	private route: ActivatedRoute,
  	private decamoney: DecamoneyService) { }

  	ngOnInit() {
		this.route.params
			.subscribe(params => {
				this.type = params.type;
				this.id = params.id;
				this.name = params.name
				this.type == 'company' ? this.loadCompany() : this.loadUser()
			})
  	}
	
	loadCompany () {
		this.decamoney.getCompany(this.id)
			.then((res) => {
				this.company = res;
				this.account = res['bank_accounts'].filter(bank => bank.name == this.name)[0]
				if (!this.account) {
					this.account = res['accounts'].filter(bank => bank.name == this.name)[0];
					this.decaAccount = true;
					console.log(this.account)
				}
			})
	}

	loadUser () {
		this.decamoney.getUser(this.id)
			.then((res) => {
				this.user = `${res['name']} ${res['lastname1']} ${res['lastname2']}`;
				this.account = res['bank_accounts'].filter(bank => bank.name == this.name)[0];
				if(!this.account) {
					this.account = res['accounts'].filter(bank => bank.account == this.name)[0];
					this.decaAccount = true;
				}
			})
	}

  	back() {
	    this.location.back();
	}
}