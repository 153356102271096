import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { DecamoneyService } from "../providers/decamoney.service";
import { Location } from '@angular/common';

@Injectable()
export class AuthGuard implements CanActivate {

  public route;

  constructor(
    private router: Router, 
    private decaS: DecamoneyService,
    private plat: Location
    ) { }

  public getToken(){
    
  }

  canActivate() {

    if (this.decaS.isAuthenticated()) {
      // logged in so return true     
      return true;
    }

    // not logged in so redirect to login page
    localStorage.clear();
    this.router.navigate(['/login']);
    return false;
  }
}
