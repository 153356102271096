import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DecamoneyService } from '../../../providers/decamoney.service';
import { LoaderEvent } from '../../../providers/loader-event';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.css']
})
export class OrderViewComponent implements OnInit {
	public order:any = {
    id: '',
		status: '',
    notes: '',
    operation: '',
		created_at: '',
		origin: {
			account_name: ''
		},
		destination: {
			account_name: '',
      thirdparty_name: '',
      thirdparty_lastname: '',
      thirdparty_birthdate: '',
      thirdparty_doc_type: '',
      thirdparty_doc_number: ''
		},
		exchange_rate: 0
	};
  public type;
  public originBank = '';
  public initialBank;
  public modalType;
  public actualUser = {
    doc_type: '',
    doc_number: ''
  };
  public company:any;
  modalRef: BsModalRef;
  public modal: boolean = false;
  @ViewChild('confirmOperation') confirmOperation;
  constructor(
  	public router:Router,
    public loader: LoaderEvent,
  	public activatedR: ActivatedRoute,
    public toastr: ToastrService,
    public modalService: BsModalService,
  	public decamoneyS: DecamoneyService,
    private location: Location) {
    this.loader.fire(true) }

  ngOnInit() {
    this.loader.fire(true);
  	this.activatedR.params
  		.subscribe( params => {
  			this.decamoneyS.getOrder(params.type,params.id)
  				.then( (res) => {
            this.type = params.type;
  					this.order = res;
            console.log(this.order)
            if (this.order.company_id != null) {
              this.decamoneyS.getCompany(this.order.company_id)
                .then((res) => {
                  this.company = res;
                })
            }
            if (this.order.operation && this.order.operation.split('-')[0]) {
              this.initialBank = this.order.operation.split('-')[0];
            }
            this.decamoneyS.getUser(this.order['user_id'])
              .then((res:any) => {
                this.actualUser = {
                  doc_type: res.doc_type,
                  doc_number: res.doc_number
                };
              });
            this.loader.fire(false);
  				});
  		});
  }

  approveOrder() {
    if (this.originBank) {
      this.order.operation = `${this.originBank}-${this.order.operation}`;
    }
    this.decamoneyS.updateOrder(this.type, this.order)
      .then((res) => {
        this.decamoneyS.approveOrder(this.type, this.order)
          .then( (res) => {
            this.toastr.success('Orden Aprobada!', '', {
              timeOut: 3000,
            });
            this.router.navigate(['/orders']);
          }, (err) => {
            this.toastr.error('Error', '', {
              timeOut: 3000,
            })
          });
      });
  }

  showModal(template: TemplateRef<any>, approve) {
    this.modalRef = this.modalService.show(template);
    this.modalType = approve?'Aprobar':'Denegar';
  }

  back() {
    this.location.back();
  }

  cciformat(number) {
    if (number.length < 20) {
      return number
    }
    let ent = number.substr(0,3);
    let ofc = number.substr(3,3);
    let account = number.substr(6,12);
    let cc = number.substr(18,2);
    return `${ent}-${ofc}-${account}-${cc}`
  }

  sendOrder(type) {
    if (type == 'Aprobar') {
      if (this.type != 'transfers') {
        if (!this.order.operation) {
          this.toastr.error('Falta agregar Nº de operación', '', {
            timeOut: 2500,
          })
        } else {
          this.order.notes = this.order.notes? this.order.notes: 'Aprobado por administrador';
          this.modalRef.hide();
          this.approveOrder();
        }
      } else {
        this.order.notes = this.order.notes? this.order.notes: 'Aprobado por administrador';
        this.modalRef.hide();
        this.approveOrder();
      }
    } else {
      this.order.notes = this.order.notes? this.order.notes: 'Cancelado por administrador';
      this.decamoneyS.deleteOrder(this.type, this.order).then((res) => {
        this.modalRef.hide();
        this.toastr.error('Orden Denegada!', '', {
          timeOut: 3000,
        });
        this.location.back();
      });
    }
  }

  openModal(){
    this.modalRef = this.modalService.show(this.confirmOperation, {class: 'modal-lg'});
  }

  rollback() {
    this.loader.fire(true)
    this.decamoneyS.rollback(this.order)
      .then((res) => {
          this.loader.fire(false);
          this.toastr.success('Se realizo la operación de rollback con exito', '', {
            timeOut: 5000,
          })
          window.location.reload();
      }, (err) => {
        this.loader.fire(false);
        this.toastr.error('Hubo un error al hacer el rollback de la ordern', '', {
          timeOut: 5000,
        })
      })
  }
}