import { Component, OnInit, ViewChild } from '@angular/core';
import { DecamoneyService } from '../../providers/decamoney.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { LoaderEvent } from '../../providers/loader-event';
import * as moment from 'moment';

@Component({
  selector: 'app-exchange-types',
  templateUrl: './exchange-types.component.html',
  styleUrls: ['./exchange-types.component.css']
})
export class ExchangeTypesComponent implements OnInit {
  @ViewChild('template') template;
  modalRef: BsModalRef;
  public selectedItem;
	public exchangeTypesDataList:any = [];
	public today = new Date();
	public sbs = {
		month_of: moment().date(1).subtract(1, 'months').format("YYYY-MM-DD"),
		rate_average: null
	};
	public allTypes = [
		{
			source: 'SBS-Compra',
			recorded_at: new Date(),
			rate: 3.5005
		},
		{
			source: 'SBS-Venta',
			recorded_at: new Date(),
			rate: 3.5005
		},
		{
			source: 'Sunat',
			recorded_at: new Date(),
			rate: 3.5005
		},
		{
			source: 'Ejemplo1',
			recorded_at: new Date(),
			rate: 3.5005
		},
		{
			source: 'Ejemplo2',
			recorded_at: new Date(),
			rate: 3.5005
		}
	];

  public new = {
    source: '',
    recorded_at: new Date(),
    rate: 3.0000
  }
  public lastMonth = {
    rate_average: 0,
    id: 0
  };

  constructor(
    public decaServ: DecamoneyService,
    public loader: LoaderEvent,
    public toastCtrl: ToastrService,
    private modalService:BsModalService) {}

  ngOnInit() {
    this.decaServ.getExchangeTypesData()
      .then((res) => {
        this.exchangeTypesDataList = res;
        this.decaServ.getSbsAverage()
          .then((res:any) => {
            this.lastMonth = res[res.length-1];
          });
      });
  }

  saveSbs() {
    this.loader.fire(true);
    this.decaServ.sbsMonth(this.sbs)
      .then((res) => {
        this.loader.fire(false);
        this.lastMonth = res['rate_average'];
        this.toastCtrl.success('SBS Mensual Cambiado');
      }, (err) => {
        this.loader.fire(false);
        this.toastCtrl.error('Month ' + err.error['month_of'][0]);
      });
  }
  updateLastMonth(){
    this.loader.fire(true);
    this.decaServ.updatesbsMonth(this.lastMonth, this.lastMonth.id)
      .then((res) => {
        this.toastCtrl.success('SBS Mensual Actualizado');
        this.loader.fire(false);
      });
  }

  saveExchangeType(type){
    this.decaServ.addExchangeTypeData(type)
      .then((res) => {
        this.toastCtrl.success('Fuente añadida!');
        this.exchangeTypesDataList.push(res);
      });
  }

  showModal(item){
    this.modalRef = this.modalService.show(this.template, {class: 'modal-dialog-centered'});
    this.selectedItem = item;
  }

  update(item){
    if (item.edit) {
      this.decaServ.updateExchangeTypesData(item)
        .then((res) => {
          this.toastCtrl.success('Fuente Actualizada');
          item.edit = false;
        });
    } else {
      item.edit = true;
    }
  }

  deleteType(){
    this.decaServ.deleteExchangeTypesData(this.selectedItem.id)
      .then((res) => {
        let index = this.exchangeTypesDataList.indexOf(this.selectedItem);
        this.exchangeTypesDataList.splice(index, 1);
        this.toastCtrl.warning('Fuente Eliminada!');
        this.modalRef.hide();
      });
  }

}