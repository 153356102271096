import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthGuard } from '../auth/auth-guard.module';
import {from, Observable, throwError} from 'rxjs';
import {map, catchError, timeout} from 'rxjs/operators';
import { SessionService } from '../providers/session.service';
import 'rxjs/add/operator/do';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthGuard, public storage: SessionService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let promise = this.storage.getObject('decamoney_admin');
    if (promise) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json')
      });
      request = request.clone({
        headers: request.headers.set('X-Admin-Email', promise.admin.email)
      });
      request = request.clone({
        headers: request.headers.set('X-Admin-Token', promise.admin.authentication_token)
      });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event
      }),
      catchError((err) => {

        return throwError(err);
      })
    )
  }

}