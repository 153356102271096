import { Component, OnInit } from "@angular/core";
import { DecamoneyService } from "../../../providers/decamoney.service";
import { LoaderEvent } from "../../../providers/loader-event";
import { OperationsService } from "../../../providers/operations.service";
import { ExcelService } from "../../../providers/excel.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.css"],
})
export class UserListComponent implements OnInit {
  public users: Array<any> = [];
  public companies: Array<any> = [];
  public usersAll: Array<any> = [];
  public all_users: Array<any> = [];
  public user_type: string = "";
  public wmoney: any = "";
  public sum = 0;
  public pepCheck: boolean = false;
  public root = {
    id: 0,
  };
  public search;
  constructor(
    public decamoneyS: DecamoneyService,
    public loader: LoaderEvent,
    public excel: ExcelService,
    public operation: OperationsService,
    public router: Router,
    public toastr: ToastrService
  ) {
    this.loader.fire(true);
  }

  ngOnInit() {
    this.loadUsers(1);
  }

  loadUsers(page?) {
    this.loadCompany(page);
    this.loadUser(page);
  }

  loadUser(page?) {
    this.decamoneyS.listUsers(page).then((res:any) => {
      this.users = this.users.concat(res.users);
      page++;
      if (res.users.length >= 100) {
        this.loadUser(page);
      } else {
        this.usersAll = this.users.concat(this.companies);
        this.all_users = this.usersAll;
        this.usersAll.sort((a, b): any => {
          if (a.created_at < b.created_at) return 1;
          else if (a.created_at > b.created_at) return -1;
          return 0;
        });
        this.loader.fire(false);
      }
    }).catch((error) => {
      if (error) {
        this.toastr.error('hubo un error, pero la data fue controlada', '', {
          timeOut: 3000
        });
        this.usersAll = this.users.concat(this.companies);
        this.all_users = this.usersAll;
        this.usersAll.sort((a, b): any => {
          if (a.created_at < b.created_at) return 1;
          else if (a.created_at > b.created_at) return -1;
          return 0;
        });
        this.loader.fire(false);
      }
    })
  }

  loadCompany(page?) {
    this.decamoneyS.listCompanies(page).then((res:any) => {
      this.companies = this.companies.concat(res);
      page++;
      if (res.length >= 100) {
        this.loadCompany(page);
      }
    }, (err) => {
      this.loader.fire(false);
    })
  }

  searchName() {
    this.usersAll = JSON.parse(JSON.stringify(this.all_users));
    let namesIndex = this.search.split(" ");
    for (var i = namesIndex.length - 1; i >= 0; i--) {
      for (var o = this.usersAll.length - 1; o >= 0; o--) {
        var existing = false;
        if (this.usersAll[o].ruc) {
          if (
            (namesIndex[i] != "" || i == 0) &&
            this.usersAll[o].name
              .toLowerCase()
              .indexOf(namesIndex[i].toLowerCase()) != -1
          ) {
            existing = true;
          }
        }
        if (this.usersAll[o].doc_type) {
          if (
            (namesIndex[i] != "" || i == 0) &&
            this.usersAll[o].name
              .toLowerCase()
              .indexOf(namesIndex[i].toLowerCase()) != -1
          ) {
            existing = true;
          } else if (
            (namesIndex[i] != "" || i == 0) &&
            this.usersAll[o].lastname1
              .toLowerCase()
              .indexOf(namesIndex[i].toLowerCase()) != -1
          ) {
            existing = true;
          } else if (
            (namesIndex[i] != "" || i == 0) &&
            this.usersAll[o].lastname2
              .toLowerCase()
              .indexOf(namesIndex[i].toLowerCase()) != -1
          ) {
            existing = true;
          }
        }
        if (!existing) {
          this.usersAll.splice(o, 1);
        }
      }
    }
  }

  exportXLSX() {
    let headers = [
      "#",
      "Apellido Paterno",
      "Apellido Materno",
      "Nombre",
      "E-mail",
      "Cuentas",
      "Balance PEN",
      "Balance USD",
      "Confirmado",
      "Verificado",
    ];
    let data = [];
    for (var i = 0; i < this.users.length; i++) {
      let toData = [
        i + 1,
        this.users[i].lastname1,
        this.users[i].lastname2,
        this.users[i].name,
        this.users[i].email,
        this.users[i].accounts.length,
        this.operation.getSum(this.users[i].accounts, "PEN"),
        this.operation.getSum(this.users[i].accounts, "USD"),
        this.users[i].is_confirmed ? "Si" : "No",
        this.users[i].is_verified ? "Si" : "No",
      ];
      data.push(toData);
    }
    this.excel.generateExcel("Clients", headers, data);
  }

  filter(e, pepUser?) {
    this.usersAll = JSON.parse(JSON.stringify(this.all_users));
    if (e) {
      if (this.wmoney != "") {
        this.usersAll = this.usersAll
          .filter(
            (user) => this.operation.getSum(user.accounts, this.wmoney) > 0
          )
          .sort((a, b): any => {
            if (
              this.operation.getSum(a.accounts, this.wmoney) <
              this.operation.getSum(b.accounts, this.wmoney)
            )
              return 1;
            if (
              this.operation.getSum(a.accounts, this.wmoney) >
              this.operation.getSum(b.accounts, this.wmoney)
            )
              return -1;
            return 0;
          });
        this.sumAcc(this.wmoney);
      }
      if (this.user_type == "user") {
        if (pepUser) {
          this.usersAll = JSON.parse(JSON.stringify(this.usersAll));
          this.usersAll = this.usersAll.filter((user) => user.pep);
          this.usersAll = this.usersAll.filter(
            (user) => user.pep[0].pep_type != "no"
          );
          return;
        }
        this.usersAll = this.usersAll.filter((user) => user.doc_type);
        this.sumAcc(this.wmoney);
        return;
      }
      if (this.user_type == "company") {
        this.usersAll = this.usersAll.filter((user) => user.ruc);
        this.sumAcc(this.wmoney);
        return;
      }
      if (pepUser) {
        this.usersAll = this.usersAll.filter((user) => user.pep);
        this.usersAll = this.usersAll.filter(
          (user) => user.pep[0].pep_type != "no"
        );
      }
    }
  }

  sumAcc(currency) {
    this.sum = 0;
    for (let i = 0; i < this.usersAll.length; i++) {
      this.sum += this.operation.getSum(this.usersAll[i].accounts, currency);
    }
  }
}
