export const data = [
    'Agropecuario',
    'Asociaciones y Organizaciones',
    'Comercio Exterior',
    'Comercio',
    'Construcción',
    'Fintech',
    'Intermediación de valores',
    'Manufactura',
    'Minería e Hidrocarburos',
    'Pesca',
    'Servicios',
    'Tecnología',
    'Otros',
]