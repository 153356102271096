import { Component, OnInit } from '@angular/core';
import { OperationsService } from '../../providers/operations.service';
import { DecamoneyService} from '../../providers/decamoney.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  public updatedDate;
  public isCollapsed = true;
  public isCollapsed2 = true;
  public isCollapsed3 = true;
  public env = environment.name;
  constructor(
    public decamoney: DecamoneyService,
    public router: Router,
    public operations: OperationsService) { }

  ngOnInit() {
    this.updatedDate = environment.timeStamp;
  }
}