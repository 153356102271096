import { Component, OnInit } from '@angular/core';
import { DecamoneyService } from '../../providers/decamoney.service';
import { LoaderEvent } from '../../providers/loader-event';
import { SendServiceService } from '../../providers/send-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-exchange-rate',
  templateUrl: './exchange-rate.component.html',
  styleUrls: ['./exchange-rate.component.css']
})
export class ExchangeRateComponent implements OnInit {
	public middle:any = 0;
  public fee:any = {
    buy_fee: 0,
    sell_fee: 0,
  };
  public log:any = [];
  public message = '';
  constructor(
  	public decamoneyS: DecamoneyService,
    public toastr: ToastrService,
    public send: SendServiceService,
    public loader: LoaderEvent) {
    this.loader.fire(true);
  }

  ngOnInit() {
    this.loadAllRates(1);
  }

  loadAllRates(page?){
    this.decamoneyS.getAdminRates(page)
      .then( (res:any) => {
        this.middle = res.filter(res => res.currency == 'PEN')[0].middle;
        let newArray = res.filter(res => res.currency == 'PEN');
        this.log = this.log.concat(newArray);
        this.loadAllFees(1);
      });
  }

  loadAllFees(page?) {
    this.decamoneyS.getFees(page)
      .then((res:any) => {
        let allFees = res;
        this.decamoneyS.getFees(2)
          .then((res2:any) => {
            allFees = allFees.concat(res2);
            let y = 0;
            for (let i = 0; i < allFees.length; i++) {
              switch (i % 2) {
                case 0:
                this.log[y]['buy_fee'] = allFees[i].fee.fee;
                this.log[y]['sell_fee'] = allFees[i+1].fee.fee;
                y++;
                break;
              }
            }
            this.fee.sell_fee = allFees[0].fee.fee;
            this.fee.buy_fee = allFees[1].fee.fee;
            let data = [];
            let labels = [];
            this.loader.fire(false);
          });
      });
  }

  getFullDate(d){
    var dd = this.validateNumber(d.getDate());
    var MM = this.validateNumber(d.getMonth() + 1);
    var yyyy = d.getFullYear();
    var hh = this.validateNumber(d.getHours());
    var mm = this.validateNumber(d.getMinutes());
    
    return `${dd}/${MM}/${yyyy} ${hh}:${mm}`
  }

  validateNumber(num) {
    if(num < 10) {
      return '0' + num
    } else {
      return num
    }
  }

  check(e) {
  	this.middle = e.toFixed(4);
  }

  sendData() {
    this.decamoneyS.newRate({ middle: this.middle, currency: 'PEN'}).then( (res) => {
      let sended:any = res;
      this.decamoneyS.newFee({fee: this.fee.buy_fee, origin: 'USD', destination: 'PEN'})
        .then( (res:any) => {
          sended.sell_fee = res.fee.fee;
          this.decamoneyS.newFee({fee: this.fee.sell_fee, origin: 'PEN', destination: 'USD'})
            .then((res:any) => {
              sended.buy_fee = res.fee.fee;
              this.log.unshift(sended);
              this.toastr.success('¡Tipo de cambio actualizado!', '', {
                timeOut: 2000,
              });
              this.send.filter('newTC');
            });
        });
    });
  }
}