import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderEvent } from '../../providers/loader-event';
import { DecamoneyService } from '../../providers/decamoney.service';
import * as moment from 'moment';

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.css']
})
export class IndicatorsComponent implements OnInit {
	public avg_amounts_per_active_user= {
    by_week: 0,
    by_month: 0,
    by_year: '',
    total: ''
  };
  public transactions_per_active_user= {
    by_week: 0,
    by_month: 0,
    by_year: 0,
    total: 0
  };
  public avg_amount_per_transactions= {
    by_week: 0,
    by_month: '',
    by_year: '',
    total: ''
  };
  public  active_users= {total: 0, last_year: 0, last_month: 0, last_week: 0};
  public  amounts= {total: '', last_year: '', last_month: '', last_week: ''};
  public  transactions= {total: 0, last_year: 0, last_month: 0, last_week: 0};
  public  users= {total: 0, last_year: 0, last_month: 0, last_week: 0};
  constructor(
  	public activatedR: ActivatedRoute,
  	public deca: DecamoneyService,
  	public loader: LoaderEvent) {
  	this.loader.fire(true);
  }

  ngOnInit() {
    this.loader.fire(true);
    this.deca.getReport().then((res)=> {
      this.avg_amounts_per_active_user = res['avg_amounts_per_active_user']
      this.transactions_per_active_user = res['transactions_per_active_user']
      this.avg_amount_per_transactions = res['avg_amount_per_transactions']
      this.active_users = res['active_users']
      this.amounts = res['amounts']
      this.transactions = res['transactions']
      this.users = res['users']
      this.loader.fire(false);
    });
  }

}