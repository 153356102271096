export const data = [
    "Alcalde",
    "Auditor en el sector público",
    "Comandante general - Ejercito, Marina, Fuerza Aerea",
    "Congresista",
    "Contralor General",
    "Decano en Universidad pública",
    "Diplomático - Consul, Ministro plenipotenciario",
    "Diplomático - Embajador",
    "Director - Organismo internacional",
    "Director General - Policia",
    "Fiscal adjunto",
    "Fiscal de la Nación",
    "Fiscal superior",
    "Fiscal supremo",
    "Fiscales provinciales",
    "Funcionario a cargo de contrataciones públicas y adquisiciones",
    "Gerente - Caja Municipal de Ahorro y Crédito",
    "Gerente - Empresa con participación estatal (Cofide, Fondo MiVivienda, BN, etc)",
    "Gerente - Gobierno regional, Gobierno Local",
    "Gerente - Ministerio público",
    "Gerente - Organo Regulador",
    "Gerente - Poder Ejecutivo",
    "Gerente - Poder Judicial",
    "Gobernador Regional",
    "Intendente, Director, Gerente, Jefe de la Administración Pública",
    "Interventor General de economia de la Administración pública",
    "Jefe de estado",
    "Jefe de gabinete",
    "Juez especializado o mixto",
    "Juez fuero militar",
    "Juez Superior",
    "Juez Supremo",
    "Ministro",
    "Notario público",
    "Oficial mayor del congreso - cargo similar",
    "Parlamentario",
    "Partidos políticos - Candidatos por el partido o en alianzas",
    "Partidos politicos - Fundadores",
    "Partidos políticos - Representantes legales, miembro organo directivo",
    "Prefecto",
    "Presidente de Gobierno Regional",
    "Presidente de la Corte Suprema",
    "Presidente de la República",
    "Presidente del Consejo de Ministros",
    "Presidente del Poder Judicial",
    "Presidente Tribunal de Justicia",
    "Procurador",
    "Procurador General",
    "Rector en Universidad pública",
    "Regidores - Municipalidades, gobiernos regionales, gobiernos locales",
    "Secretario General - Ministerio público",
    "Secretario General - Poder Ejecutivo",
    "Secretario General - Poder Judicial",
    "Sub Prefecto",
    "Superintendente  de la administración pública",
    "Titular de logistica del sector público",
    "Titular de tesoreria, finanzas, presupuesto  del sector público",
    "Vice-presidente de la República",
    "Vicegobernador regional",
    "Viceministro",
    "Vocal de la corte Superior o Suprema"
]