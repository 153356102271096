import { Component, OnInit } from '@angular/core';
import { DecamoneyService } from '../../../providers/decamoney.service';
import { LoaderEvent } from '../../../providers/loader-event';

@Component({
  selector: 'app-banks-list',
  templateUrl: './banks-list.component.html',
  styleUrls: ['./banks-list.component.css']
})
export class BanksListComponent implements OnInit {
	public orders = {
    depPEN: 0,
    depUSD: 0,
    retPEN: 0,
    retUSD: 0
  };
  public allOrders = {
    deposits: [],
    withdrawals: []
  }
  public decamoney = {
    decaPEN: 0,
    decaUSD: 0,
    feePEN: 0,
    feeUSD: 0
  };

  constructor(
  	public _decaService: DecamoneyService,
    public _loader: LoaderEvent) {
    this._loader.fire(true);
  }

  ngOnInit() {
    this.getAllDeposits(1);
    this.getAllWithdrawals(1);
    setTimeout(() => {
      this.countOrders();
    },6000);
  }

  getAllDeposits(page?){
    this._decaService.getOrders('deposits', page)
      .then((res)=> {
        let newDeposits = res['orders'];
        this.allOrders['deposits'] = this.allOrders['deposits'].concat(newDeposits);
        page++;
        this.getAllDeposits(page);
      }, (err) => {
        // console.log(err);
      });
  }

  getAllWithdrawals(page?){
    this._decaService.getOrders('withdrawals', page)
      .then((res)=> {
        let newWith = res['orders'];
        this.allOrders['withdrawals'] = this.allOrders['withdrawals'].concat(newWith);
        page++;
        this.getAllWithdrawals(page);
      }, (err) => {
        // console.log(err);
      });
  }

  countOrders(){    
    this.orders = {
      depPEN: this.filter(this.allOrders['deposits'], 'PEN'),
      depUSD: this.filter(this.allOrders['deposits'], 'USD'),
      retPEN: this.filter(this.allOrders['withdrawals'], 'PEN'),
      retUSD: this.filter(this.allOrders['withdrawals'], 'USD')
    }
    this._decaService.dataDecamoney().then(res => {
      this.decamoney = {
        decaPEN: res['accounts'].filter(acc => acc.account == 'Cuenta PEN - Decamoney')[0].balance,
        decaUSD: res['accounts'].filter(acc => acc.account == 'Cuenta USD - Decamoney')[0].balance,
        feePEN: res['accounts'].filter(acc => acc.account == 'Fee PEN')[0].balance,
        feeUSD: res['accounts'].filter(acc => acc.account == 'Fee USD')[0].balance
      }
    });
    setTimeout(() => {
      this._loader.fire(false);
    })    
  }

  filter(array:Array<any>, currency) {
    return array.filter(arr => arr.destination.currency == currency && arr.status == 'completed')
    .map(c => Number(c.destination.amount))
    .reduce((sum, current) => sum + current, 0);
  }
}