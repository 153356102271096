import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OperationsService {

  constructor() { }

  public getSum(data:Array<any>, currency) : number {
    let sum = 0;
    for (var i = data.length - 1; i >= 0; i--) {
      if (data[i].currency == currency) {
        sum += Number(data[i].balance);
      }
    }
    return sum
  }

}
