import { Component, OnInit, TemplateRef } from '@angular/core';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {MatDatepickerModule} from '@angular/material/datepicker';


@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {
	modalRef: BsModalRef;
	startDate = moment(new Date());
	public date = {
		holiday: false,
		startAt: "09:00"
	}
	public week = [
		{
			day: 'Lunes',
			open: moment().set({ hour: 9, minute: 0}).format('LT'),
			close: moment().set({ hour: 17, minute: 0}).format('LT'),
			closed: false
		},
		{
			day: 'Martes',
			open: moment().set({ hour: 9, minute: 0}).format('LT'),
			close: moment().set({ hour: 17, minute: 0}).format('LT'),
			closed: false
		},
		{
			day: 'Miercoles',
			open: moment().set({ hour: 9, minute: 0}).format('LT'),
			close: moment().set({ hour: 17, minute: 0}).format('LT'),
			closed: false
		},
		{
			day: 'Jueves',
			open: moment().set({ hour: 9, minute: 0}).format('LT'),
			close: moment().set({ hour: 17, minute: 0}).format('LT'),
			closed: false
		},
		{
			day: 'Viernes',
			open: moment().set({ hour: 9, minute: 0}).format('LT'),
			close: moment().set({ hour: 17, minute: 0}).format('LT'),
			closed: false
		},
		{
			day: 'Sabado',
			open: moment().set({ hour: 9, minute: 0}).format('LT'),
			close: moment().set({ hour: 13, minute: 0}).format('LT'),
			closed: false
		},
		{
			day: 'Domingo',
			open: moment().set({ hour: 0, minute: 0}).format('LT'),
			close: moment().set({ hour: 0, minute: 0}).format('LT'),
			closed: true
		},
	];

  constructor(
  	public modalService: BsModalService
  	) { }

  ngOnInit() {
  }

  hello(date) {
  	console.log(date);
  }

  showModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
}
