import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { promise } from 'selenium-webdriver';
import { ToastrService } from 'ngx-toastr';

let apiUrl = environment.endpoint;

@Injectable({
  providedIn: 'root'
})
export class DecamoneyService {
  headers:HttpHeaders;


  constructor(
    private http: HttpClient,
    public toastr: ToastrService,
    ) {
  }

  login(credentials){
    return new Promise( (resolve, reject) => {
      this.http.post(apiUrl + '/v1/administrators', credentials)
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  listUsers(page = 1) {
    return new Promise( (resolve, reject) => {
      this.http.get(apiUrl + '/v1/admin/users' + '?page=' + page)
      .subscribe( res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  listCompanies(page = 1) {
    return new Promise( (resolve, reject) => {
      this.http.get(apiUrl + '/v1/admin/companies' + '?page=' + page)
      .subscribe( res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  getOrders(type, page = 1) {
    return new Promise( (resolve, reject) => {
      this.http.get(apiUrl + '/v1/admin/' + type + '?page=' + page)
      .subscribe( res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  updateOrder(type, order) {
    return new Promise( (resolve, reject) => {
      this.http.put(`${apiUrl}/v1/admin/${type}/${order.id}`, order)
      .subscribe( res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  deleteOrder(type, order) {
    return new Promise( (resolve, reject) => {
      this.http.request('delete', `${apiUrl}/v1/admin/${type}/${order.id}`, {body: order})
      .subscribe( res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  getRates() {
    return new Promise( (resolve, reject) => {
      this.http.get(apiUrl + '/v1/rates')
      .subscribe( res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  getAdminRates(page = 1) {
    return new Promise( (resolve, reject) => {
      this.http.get(apiUrl + '/v1/admin/exchange_rates' + '?page=' + page)
      .subscribe( res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  // getInfoRates(id) {
  //   this.setHeaders();
  //   return new Promise( (resolve, reject) => {
  //     this.http.get(apiUrl + '/v1/admin/exchange_rates/' + id)
  //     .subscribe( res => {
  //       resolve(res);
  //     }, (err) => {
  //       reject(err);
  //     });
  //   });
  // }

  newRate(data) {
    return new Promise( (resolve, reject) => {
      this.http.post(apiUrl + '/v1/admin/exchange_rates', data)
      .subscribe( res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  approveOrder(type ,order) {
    return new Promise( (resolve, reject) => {
      this.http.post(`${apiUrl}/v1/admin/${type}/${order.id}/approve`, order)
      .subscribe( res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  getOrder(type, id) {
    return new Promise( (resolve, reject) => {
      this.http.get(`${apiUrl}/v1/admin/${type}/${id}`)
      .subscribe( res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  getBankAccounts() {
    return new Promise( (resolve, reject) => {
      this.http.get(apiUrl + '/v1/admin/bank_accounts')
      .subscribe( res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  getDetailAccounts(id){
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl + '/v1/admin/accounts/' + id)
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  getBankAccountsId(id) {
    return new Promise( (resolve, reject) => {
      this.http.get(apiUrl + '/v1/admin/bank_accounts/' + id)
      .subscribe( res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  getUser(id) {
    return new Promise( (resolve, reject) => {
      this.http.get(apiUrl + '/v1/admin/users/' + id)
      .subscribe( res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  updateUser(id, fields){
    return new Promise( (resolve, reject) => {
      this.http.put(apiUrl + '/v1/admin/users/' + id, fields)
        .subscribe( res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  deleteUser(id){
    return new Promise( (resolve, reject) => {
      this.http.delete(apiUrl + '/v1/admin/users/' + id)
        .subscribe( res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  dataDecamoney() {
    return new Promise( (resolve, reject) => {
      this.http.get(apiUrl + '/v1/admin/users/decamoney')
      .subscribe( res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  getFees(page = 1) {
    return new Promise( (resolve, reject) => {
      this.http.get(apiUrl + '/v1/admin/fees' + '?page=' + page)
      .subscribe( res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  newFee(fee) {
    return new Promise( (resolve, reject) => {
      this.http.post(apiUrl + '/v1/admin/fees', {fee: fee})
      .subscribe( res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  createCoupon(coupon){
    return new Promise( (resolve, reject) => {
      this.http.post(apiUrl + '/v1/admin/coupons', coupon)
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  editCoupon(coupon){
    return new Promise( (resolve, reject) => {
      this.http.put(apiUrl + '/v1/admin/coupons/' + coupon.id , coupon)
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  getAllCoupons(){
    return new Promise( (resolve, reject) => {
      this.http.get(apiUrl + '/v1/admin/coupons')
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  deleteCoupon(id){
    return new Promise((resolve, reject) => {
      this.http.delete(apiUrl + '/v1/admin/coupons/' + id)
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  addExchangeTypeData(data) {
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl + '/v1/admin/data_providers', data)
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  getExchangeTypesData() {
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl + '/v1/admin/data_providers')
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  deleteExchangeTypesData(id) {
    return new Promise((resolve, reject) => {
      this.http.delete(apiUrl + '/v1/admin/data_providers/' + id)
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  updateExchangeTypesData(type) {
    return new Promise( (resolve, reject) => {
      this.http.put(`${apiUrl}/v1/admin/data_providers/${type.id}`, type)
      .subscribe( res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  sbsMonth(data) {
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl + '/v1/admin/sbs_rates', data)
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }
  
  updatesbsMonth(data, id){
    return new Promise((resolve, reject) => {
      this.http.put(apiUrl + '/v1/admin/sbs_rates/' + id, data)
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  getSbsAverage() {
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl + '/v1/admin/sbs_rates')
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  getVersion(){
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl + '/v1/version')
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  createConfig(config){
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl + '/v1/admin/config_options', config)
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  editConfig(id, config){
    return new Promise((resolve, reject) => {
      this.http.put(apiUrl + '/v1/admin/config_options/' + id, config)
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  deleteConfig(id){
    return new Promise((resolve, reject) => {
      this.http.delete(apiUrl + '/v1/admin/config_options/' + id)
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getConfig(){
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl + '/v1/admin/config_options')
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getReport() {
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl + '/v1/admin/reports/dashboard')
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getCompany(id) {
    return new Promise( (resolve, reject) => {
      this.http.get(apiUrl + '/v1/admin/companies/' + id)
      .subscribe( res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }
  
  getPending() {
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl + '/v1/admin/pending')
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  rollback(order) {
    return new Promise((resolve, reject) => {
      this.http.put(apiUrl + 'v1/admin/deposits/' + order.id + '/rollback', order)
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err)
        })
    })
  }

  createUser(user){
    return new Promise((resolve, reject) => {
      this.http.post(`${apiUrl}/users`, {user: user})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    })
  }

  createCompany(company){
    return new Promise((resolve, reject) => {
      this.http.post(`${apiUrl}/v1/admin/companies`, {company: company})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    })
  }

  registerPEP(params, id){
    return new Promise((resolve, reject) => {
      this.http.post(`${apiUrl}/users/${id}/pep`, {user: params})
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  isAuthenticated(){
    if (localStorage.getItem('token')) {
      return true;
    }
      return false
  }
}

  