import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DecamoneyService } from '../../providers/decamoney.service';
import { LoaderEvent } from '../../providers/loader-event';
import { SessionService } from '../../providers/session.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  providers: [
  	FormBuilder,
    DecamoneyService
  ]
})
export class LoginComponent implements OnInit {
	public userForm: FormGroup;
  public passwordType:string = 'password';
  constructor(
  		private _fb: FormBuilder,
  		private router: Router,
      private session: SessionService,
      public toastr: ToastrService,
  		private decamoneyS: DecamoneyService,
      private loader: LoaderEvent
  	) {}

  ngOnInit() {
  	this.userForm = this._fb.group({
  		email: ['', Validators.required],
  		password: ['', Validators.required]
  	});
  }

  hideShowPassword(){
    this.passwordType = this.passwordType==='password'?'text':'password';
  }

  onSubmit(user, valid) {
    this.loader.fire(true);
  	if (valid) {
  		this.decamoneyS.login(user).then((res) => {
        this.session.setObject('decamoney_admin', res);
        this.session.setItem('token', res['admin'].authentication_token);
	      this.router.navigate(['/dashboard']);
        this.loader.fire(false);
	    }, (err) => {
        this.loader.fire(false);
        this.toastr.error('Datos incorrectos', '', {
          timeOut: 3000,
        });
      });
  	} else {
      this.loader.fire(false);
  		this.toastr.error('Falta completar campos', '', {
        timeOut: 3000,
      });
  	}
  }
}