import { Component, OnInit, ViewChild } from '@angular/core';
import { DecamoneyService } from '../../providers/decamoney.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-limits',
  templateUrl: './limits.component.html',
  styleUrls: ['./limits.component.css']
})
export class LimitsComponent implements OnInit {
	public version = {
	    id: '',
	    value: ''
	};
  constructor(public decamoneyS: DecamoneyService,
    public toastCtrl: ToastrService) { }

  	ngOnInit() {
	  	this.decamoneyS.getConfig()
	  		.then((res:any) => {
	        this.version = res.filter(el => el.parameter == 'last_version')[0];
		});
  	}

  	create(version){
	    this.decamoneyS.editConfig(this.version.id, {parameter: 'last_version', value: version})
	      .then((res) => {
	        this.toastCtrl.success('Versión actualizada');
	      });
	}
}