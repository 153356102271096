export const data = [   
        'Abogado',
        'Actor, Actriz',
        'Artista',
        'Administrador',
        'Aduanero',
        'Aeromozo, Azafata',
        'Agente',
        'Agente de bolsa',
        'Agente de turismo',
        'Agricultor, Agrónomo',
        'Agrólogo',
        'Albañil',
        'Ama de casa',
        'Analista de Sistema y Computación',
        'Antropólogo',
        'Arqueólogo',
        'Archivero',
        'Armador de barco',
        'Arquitecto',
        'Artesano',
        'Asistente Social',
        'Atleta',
        'Arbitro',
        'Autor Literario',
        'Avicultor',
        'Bacteriólogo',
        'Biólogo',
        'Basurero / Barrendero',
        'Cajero',
        'Camarero / Barman',
        'Cocinero / Chef',
        'Cambista',
        'Campesino',
        'Capataz',
        'Cargador',
        'Carpintero',
        'Cartero',
        'Cerrajero',
        'Cobrador',
        'Comerciante / Vendedor',
        'Conductor / Taxista',
        'Conserje / Portero',
        'Guardian / Vigilante',
        'Community Manager',
        'Constructor',
        'Consultor',
        'Contador',
        'Contratista',
        'Corte y confeccion de ropa',
        'Cosmetólogo, Peluquero y Barbero',
        'Decorador',
        'Desarrollador / Programador',
        'Dibujante',
        'Dentista / Odontólogo',
        'Deportista profesional',
        'Distribuidor',
        'Diseñador',
        'Docente / Profesor',
        'Economista',
        'Empleado',
        'Empleado(a) del hogar / Nana',
        'Empresario',
        'Empresario exportador / Empresario importador',
        'Enfermero',
        'Ensamblador',
        'Escultor',
        'Estudiante',
        'Fotógrafo',
        'Operadores de camara',
        'Cine y TV',
        'Locutor de radio y TV',
        'Ganadero',
        'Gasfitero',
        'Historiador',
        'Ingeniero',
        'Interprete / Traductor',
        'Jardinero',
        'Jockey',
        'Orfebre',
        'Jubilado',
        'Laboratorista',
        'Liquidador',
        'Maquinista / Operador de maquinaria',
        'Marketero',
        'Martillero / Subastador',
        'Mayorista, Comercio al por mayor',
        'Mecánico',
        'Médico / Cirujano',
        'Metalurgista',
        'Miembro de las fuerzas armadas',
        'Nutricionista',
        'Obrero / Operador',
        'Obstetriz',
        'Organizador de eventos',
        'Panadero / Pastelero',
        'Paramédico',
        'Periodista',
        'Perito',
        'Pescador',
        'Piloto',
        'Pintor',
        'Policia municipal',
        'Policia PNP',
        'Productor de cine / radio / televisión / teatro',
        'Productor',
        'Psicológo / Terapeuta',
        'Publicista',
        'Quiropráctico',
        'Relacionista público',
        'Relojero',
        'Reparación de automoviles',
        'Reparador de aparatos electrodomésticos',
        'Repartidor',
        'Sacerdote / Monja',
        'Secretaria / Recepcionista / Telefonista',
        'Seguridad / Guardaespalda / Guardia de seguridad',
        'Servicio de almacenamiento',
        'Servicio de alquiler de vehiculos',
        'Servicio de alquiler de videos',
        'Sociólogo',
        'Tasador',
        'Técnico',
        'Torero',
        'Tramitador',
        'Transporte de carga y/o mudanza',
        'Transportista',
        'Vendedor ambulante',
        'Veterinario',
        'Visitador médico',
        'Zapatero',
        'Zoólogo',
        'Otro'
]