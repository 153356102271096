import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth-guard.module';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UserComponent } from './components/users/user/user.component';
import { UserListComponent } from './components/users/user-list/user-list.component';
import { UserCreateComponent } from './components/users/user-create/user-create.component';
import { OrderComponent } from './components/orders/order/order.component';
import { OrderListComponent } from './components/orders/order-list/order-list.component';
import { OrderViewComponent } from './components/orders/order-view/order-view.component';
import { ExchangeRateComponent } from './components/exchange-rate/exchange-rate.component';
import { TransactionsComponent } from './components/transactions/transactions/transactions.component';
import { TransactionsListComponent } from './components/transactions/transactions-list/transactions-list.component';
import { BanksComponent } from './components/banks/banks/banks.component';
import { BanksListComponent } from './components/banks/banks-list/banks-list.component';
import { DatamarketComponent } from './components/datamarket/datamarket.component';
import { UserBankComponent } from './components/users/user-bank/user-bank.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationsCreateComponent } from './components/notifications-create/notifications-create.component';
import { LimitsComponent } from './components/limits/limits.component';
import { IndicatorsComponent } from './components/indicators/indicators.component';
import { CouponsComponent } from './components/coupons/coupons.component';
import { BitcoinComponent } from './components/bitcoin/bitcoin.component';
import { RucComponent } from './components/ruc/ruc.component';
import { ExchangeTypesComponent } from './components/exchange-types/exchange-types.component';
import { ConfigOptionsComponent } from './components/config-options/config-options.component';
import { CompanyComponent } from './components/users/company/company.component'
import { CompanyCreateComponent } from './components/users/company-create/company-create.component';
import { MarketNotificationsComponent } from './components/market-notifications/market-notifications.component';

const routes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'login', component: LoginComponent },
	{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
	{ path: 'indicators/:type', component: IndicatorsComponent, canActivate: [AuthGuard] },
	
	{ path: 'users', component: UserComponent, children: [
		{ path: '', component: UserListComponent, pathMatch: 'full', canActivate: [AuthGuard] },
		{ path: 'create/:new', component: UserCreateComponent, pathMatch: 'full', canActivate: [AuthGuard] },
		{ path: 'edit/:id', component: UserCreateComponent, pathMatch: 'full', canActivate: [AuthGuard] },
		{ path: 'bank/:id/:name/:type', component: UserBankComponent, pathMatch: 'full', canActivate: [AuthGuard] },
		{ path: 'edit-company/:id', component: CompanyComponent, pathMatch: 'full', canActivate: [AuthGuard] },
		{ path: 'create-company/:user_id/:user_email', component: CompanyCreateComponent, pathMatch: 'full', canActivate: [AuthGuard] }
	]},

	{ path: 'orders', component: OrderComponent, children: [
		{ path: '', component: OrderListComponent, pathMatch: 'full', canActivate: [AuthGuard] },
		{ path: ':type', component: OrderListComponent, pathMatch: 'full', canActivate: [AuthGuard] },
		{ path: 'detail/:type/:id', component: OrderViewComponent, pathMatch: 'full', canActivate: [AuthGuard]}
	]},

	{ path: 'transactions', component: TransactionsComponent, children: [
		{ path: '', component: TransactionsListComponent, pathMatch: 'full', canActivate: [AuthGuard] },
	]},
	{ path: 'banks', component: BanksComponent, children: [
		{ path: '', component: BanksListComponent, pathMatch: 'full', canActivate: [AuthGuard] },
	]},
	{ path: 'ruc', component: RucComponent, pathMatch: 'full', canActivate: [AuthGuard] },
	{ path: 'bitcoin', component: BitcoinComponent, pathMatch: 'full', canActivate: [AuthGuard] },
	{ path: 'exchange-types', component: ExchangeTypesComponent, pathMatch: 'full', canActivate: [AuthGuard] },
	{ path: 'exchange-rate', component: ExchangeRateComponent, pathMatch: 'full', canActivate: [AuthGuard] },
	{ path: 'coupons', component: CouponsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
	{ path: 'schedule', component: ScheduleComponent, pathMatch: 'full', canActivate: [AuthGuard] },
	{ path: 'aboutus', component: AboutusComponent, pathMatch: 'full', canActivate: [AuthGuard] },
	{ path: 'datamarket', component: DatamarketComponent, pathMatch: 'full', canActivate: [AuthGuard] },
	{ path: 'limits', component: LimitsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
	{ path: 'notifications', component: NotificationsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
	{ path: 'notifications/create', component: NotificationsCreateComponent, pathMatch: 'full', canActivate: [AuthGuard] },
	{ path: 'config-options', component: ConfigOptionsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
	{ path: 'market-notifications', component: MarketNotificationsComponent, pathMatch: 'full', canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
