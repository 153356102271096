import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { promise } from 'selenium-webdriver';
import { ToastrService } from 'ngx-toastr';

let apiUrl = environment.rate_enpoint;
@Injectable({
  providedIn: 'root'
})
export class RateMicroserviceService {
  cors = 'https://corsproxyserve.herokuapp.com/';

  constructor(
    private http: HttpClient,
    public toastr: ToastrService,
  ) { }

  httpOptions = {
    headers: new HttpHeaders ({
      'Content-Type':  'application/json',
    }), responseType: 'text' as 'json'
  }

  getStatus() {
    return new Promise( (resolve, reject) => {
      this.http.get(`${this.cors}${apiUrl}/api/status`, this.httpOptions)
      .subscribe(res => {
        resolve(res)
      }, (err) => {
        reject(err);
      });
    });
  }

  getTime() {
    return new Promise( (resolve, reject) => {
      this.http.get(`${this.cors}${apiUrl}/api/time`, this.httpOptions)
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  getSensitivity() {
    return new Promise( (resolve, reject) => {
      this.http.get(`${this.cors}${apiUrl}/api/sensitivity`, this.httpOptions)
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  getWeights() {
    return new Promise( (resolve, reject) => {
      this.http.get(`${this.cors}${apiUrl}/api/weight`, this.httpOptions)
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  updateStatus(value: string) {
    return new Promise( (resolve, reject) => {
      this.http.put(`${this.cors}${apiUrl}/api/status/${value}`, '', this.httpOptions)
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
        console.log(err)
      });
    });
  }

  updateTime(value: string) {
    return new Promise( (resolve, reject) => {
      this.http.put(`${this.cors}${apiUrl}/api/time/${value}`, '', this.httpOptions)
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  updateSensitivity(value: string) {
    return new Promise( (resolve, reject) => {
      this.http.put(`${this.cors}${apiUrl}/api/sensitivity/${value}`, '', this.httpOptions)
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  updateWeights(value: {
    acomo: string,
    instakash: string,
    kambista: string,
    rextie: string,
    tkambio: string,
    tucambista: string
  }) {
    return new Promise( (resolve, reject) => {
      this.http.put(`${this.cors}${apiUrl}/api/weight`, value, this.httpOptions)
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }
}
