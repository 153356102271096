import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LoaderEvent } from './providers/loader-event';
import { DecamoneyService} from './providers/decamoney.service';
import { SendServiceService } from './providers/send-service.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
	@ViewChild('template') template;
	modalRef: BsModalRef;
	public showLoader = false;
	public showLayout = true;
	constructor(
		private router: Router,
		private loader: LoaderEvent,
		private decaS: DecamoneyService,
		private modalService:BsModalService,
		public sendServ: SendServiceService
	){
		this.sendServ.listen().subscribe((m:any) => {
			if (m == 'showLogout') this.onFilterClick(m);
		});
	}


	onFilterClick(event) {
		this.modalRef = this.modalService.show(this.template, {class: 'modal-dialog-centered'});
	}

	ngOnInit() {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (this.router.url == '/login') {
					this.showLayout = false;
				} else {
					this.showLayout = true;
				}
			}
		})
		this.registerBroadcast();
	}

	changeOfRoutes() {
		this.sendServ.filter('changed');
	}

	registerBroadcast() {
		this.loader.on()
			.subscribe( value => {
				this.showLoader = value;
			});
	}

	logout() {
		this.modalRef.hide();
	  	localStorage.clear();
	  	this.router.navigate(['/']);
  	}
}