import { Component, OnInit } from '@angular/core';
import { ExternaldataService } from '../../providers/externaldata.service';
import { LoaderEvent } from '../../providers/loader-event';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ruc',
  templateUrl: './ruc.component.html',
  styleUrls: ['./ruc.component.css']
})
export class RucComponent implements OnInit {
	public ruc:String = '20605348875';
  public info = {
    representante_legal: '',
    razon_social: '',
    empleados: [],
    habido: false,
    activo: false,
    domicilio: ''
  }
  constructor(
  	public external: ExternaldataService,
    public toastr: ToastrService,
  	public _loader: LoaderEvent
  	) { }

  ngOnInit() {
  }

  sendRuc(){
    this._loader.fire(true);
    this.external.getRucInfo(this.ruc)
      .then((res) => {
        this.info = {
          representante_legal: res['representante_legal'][Object.keys(res['representante_legal'])[0]].nombre,
          razon_social: res['razon_social'],
          empleados: [],
          habido: res['contribuyente_condicion']=='HABIDO'?true:false,
          activo: res['contribuyente_estado']=='ACTIVO'?true:false,
          domicilio: res['domicilio_fiscal']
        }
        this.toastr.success('¡Consulta exitosa!');
        this._loader.fire(false);
      }, (err) => {
        this.toastr.error('Error en la consulta, Intentalo nuevamente');
        this._loader.fire(false);
      });
  }
}