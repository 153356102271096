export const data = [
    {
        name: 'Conyuge',
        value: 'Conyuge'
    },
    {
        name: 'Conviviente',
        value: 'Conviviente'
    },
    {
        name: 'Hijo(a)',
        value: 'Hijx'
    },
    {
        name: 'Padre',
        value: 'Madre'
    },
    {
        name: 'Hermano(a)',
        value: 'Hermanx'
    },
    {
        name: 'Abuelo(a)',
        value: 'Abuelx'
    },
    {
        name: 'Nieto(a)',
        value: 'Nietx'
    },
    {
        name: 'Suegro(a)',
        value: 'Suegrx'
    }
]