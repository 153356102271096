import { Component, OnInit, ViewChild } from '@angular/core';
import { DecamoneyService } from '../../providers/decamoney.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
	public notifications:Array<any> = [
    {}
  ];
  @ViewChild('editModal') editModal;
  modalRef: BsModalRef;
  constructor(public decamoneyS: DecamoneyService,
    public toastCtrl: ToastrService,
    private modalService:BsModalService) { }

  ngOnInit() {
  	this.decamoneyS.getConfig()
      .then((res:any) => {
        this.notifications = this.notifications.filter(nt => nt.parameter == 'global_message');
  		});
  }

  openModal(){
    this.modalRef = this.modalService.show(this.editModal, {class: 'modal-lg'});
  }


  createMessage(msg){
    this.decamoneyS.editConfig(this.notifications[0].id, { parameter: 'global_message', value: msg})
      .then((res) => {
        this.toastCtrl.success('Mensaje actualizado');
        this.modalRef.hide();
      });
  }
}