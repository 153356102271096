import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { LoadersCssModule } from 'angular2-loaders-css';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HeaderComponent } from './layouts/header/header.component';
import { UserComponent } from './components/users/user/user.component';
import { UserListComponent } from './components/users/user-list/user-list.component';
import { OrderComponent } from './components/orders/order/order.component';
import { OrderListComponent } from './components/orders/order-list/order-list.component';
import { OrderViewComponent } from './components/orders/order-view/order-view.component';

import { Ng2FilterPipeModule } from 'ng2-filter-pipe';
import { AuthGuard } from './auth/auth-guard.module';
import { TokenInterceptor } from './auth/token.interceptor';
import { Broadcaster } from './providers/broadcaster';
import { LoaderEvent } from './providers/loader-event';
import { SessionService } from './providers/session.service';
import { SendServiceService } from './providers/send-service.service';
import { ExchangeRateComponent } from './components/exchange-rate/exchange-rate.component';
import { UserCreateComponent } from './components/users/user-create/user-create.component';
import { MenuComponent } from './layouts/menu/menu.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import {DataTableModule} from "angular-6-datatable";

import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatNativeDateModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { TransactionsListComponent } from './components/transactions/transactions-list/transactions-list.component';
import { TransactionsComponent } from './components/transactions/transactions/transactions.component';
import { BanksComponent } from './components/banks/banks/banks.component';
import { BanksListComponent } from './components/banks/banks-list/banks-list.component';
import { SupportComponent } from './components/support/support.component';
import { DatamarketComponent } from './components/datamarket/datamarket.component';
import { UserBankComponent } from './components/users/user-bank/user-bank.component';
import { ChartsModule } from 'ng2-charts';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { NgKnifeModule } from 'ng-knife';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { LimitsComponent } from './components/limits/limits.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationsCreateComponent } from './components/notifications-create/notifications-create.component';
import { IndicatorsComponent } from './components/indicators/indicators.component';
import { CouponsComponent } from './components/coupons/coupons.component';
import { BitcoinComponent } from './components/bitcoin/bitcoin.component';
import { RucComponent } from './components/ruc/ruc.component';

import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { ExchangeTypesComponent } from './components/exchange-types/exchange-types.component';
import { ConfigOptionsComponent } from './components/config-options/config-options.component';
import { CompanyComponent } from './components/users/company/company.component';
import { CompanyCreateComponent } from './components/users/company-create/company-create.component';
import { MarketNotificationsComponent } from './components/market-notifications/market-notifications.component';

registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    UserComponent,
    UserListComponent,
    OrderComponent,
    OrderListComponent,
    OrderViewComponent,
    ExchangeRateComponent,
    UserCreateComponent,
    MenuComponent,
    TransactionsListComponent,
    TransactionsComponent,
    BanksComponent,
    BanksListComponent,
    SupportComponent,
    DatamarketComponent,
    UserBankComponent,
    ScheduleComponent,
    AboutusComponent,
    LimitsComponent,
    NotificationsComponent,
    NotificationsCreateComponent,
    IndicatorsComponent,
    CouponsComponent,
    BitcoinComponent,
    RucComponent,
    ExchangeTypesComponent,
    ConfigOptionsComponent,
    CompanyComponent,
    CompanyCreateComponent,
    MarketNotificationsComponent,
  ],
  imports: [
    BrowserModule,
    LoadersCssModule,
    HttpClientModule,
    AccordionModule,
    DataTableModule,
    ChartsModule,
    NgKnifeModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    AppRoutingModule,
    Ng2FilterPipeModule,
    BrowserAnimationsModule
  ],
  providers: [
    LoaderEvent,
    AuthGuard,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
    },
    MatDatepickerModule,
    SendServiceService,
    Broadcaster,
    SessionService,
    {provide: MAT_DATE_LOCALE, useValue: 'en-ES'},
    {provide: LOCALE_ID, useValue: "es-ES" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
